// routes
import Router from "./utils/router";
// theme
import ThemeProvider from "./theme";
// components
import { PrimeReactProvider } from "primereact/api";

import "primereact/resources/primereact.min.css";
import ThemeSettings from "./components/settings";
import ScrollToTop from "./components/other/ScrollToTop";
import { ProgressBarStyle } from "./components/other/ProgressBar";
import MotionLazyContainer from "./components/other/animate/MotionLazyContainer";
// GraphQL
import { GqlProvider } from "./GqlProvider";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";

// ---------------------------------------------------

const api = import.meta.env.VITE_APP_API;

Sentry.init({
  environment: api?.includes("localhost")
    ? "development"
    : api?.includes("staging")
    ? "staging"
    : "production",
  dsn: "https://706025ec78d1a14801166f59fe3e278a@o1353374.ingest.us.sentry.io/4507844460806144",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ProgressBarStyle />
          <ScrollToTop />
          <GqlProvider>
            <PrimeReactProvider>
              <Router />
            </PrimeReactProvider>

            <Toaster
              position={"bottom-right"}
              toastOptions={{
                style: {
                  background: "#515d68",
                  color: "#fff",
                },
              }}
            />
          </GqlProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
