import {
  Alert,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import {
  useGetTransactionListByPlayerIdQuery,
  useUpdatePlayerFullNameMutation,
} from "../generated/graphql";
import { orderBy } from "lodash";

// ----------------------------------------------------------------------
interface PlayerTransactionsProps {
  player: any;
}

export default function PlayerTransactions({
  player,
}: PlayerTransactionsProps) {
  // Pagination
  const [cursor, setCursor] = useState<number | null>(null);
  const [limit, setLimit] = useState<number>(10);

  // Sorting
  const [sortDirectionAsc, setSortDirectionAsc] = useState<boolean>(false);
  const [sortByAttribute, setSortByAttribute] = useState<string>("date");

  // Concated List
  const [concatedList, setConcatedList] = useState<any[]>([]);

  const [
    { data: getPlayerTransactionsData, error: getPlayerTransactionsError },
  ] = useGetTransactionListByPlayerIdQuery({
    variables: {
      playerId: player.id,
      pagination: {
        limit: limit,
        cursor: cursor,
      },
    },
  });

  const transactions =
    getPlayerTransactionsData?.getTransactionListByPlayerId.transactions;
  const _cursor =
    getPlayerTransactionsData?.getTransactionListByPlayerId.cursor;
  const _hasMore =
    getPlayerTransactionsData?.getTransactionListByPlayerId.hasMore;
  const _totalCount =
    getPlayerTransactionsData?.getTransactionListByPlayerId.totalCount;

  // concat transactions list on new data
  useEffect(() => {
    if (transactions) {
      setConcatedList((prev) => [...prev, ...transactions]);
    }
  }, [transactions]);

  const sortItems = (items: any) => {
    const direction = sortDirectionAsc ? "asc" : "desc";
    const sorted = orderBy(items, (o) => o[sortByAttribute], direction);
    return sorted;
  };

  const changeSort = (attribute: any) => {
    if (attribute === sortByAttribute) {
      const newSort = !sortDirectionAsc;
      setSortDirectionAsc(newSort);
    } else setSortByAttribute(attribute);
  };

  const sortedItems = useMemo(() => sortItems(concatedList), [concatedList]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          height: { xs: "auto", sm: "50px" },
          width: { xs: "100%", sm: "auto" },
          mb: 2,
        }}
      >
        {transactions && transactions.length === 0 ? (
          <Alert severity="warning">No Transactions Found!</Alert>
        ) : (
          <Alert severity="info">{_totalCount} Transactions Found</Alert>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" sx={{ overflowX: "scroll" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#2d2f38" }}>
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("type")}
                  active={sortByAttribute === "type"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Transaction Type
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("amount")}
                  active={sortByAttribute === "amount"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("date")}
                  active={sortByAttribute === "date"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems?.map((transaction: any, index: any) => {
              const isEven = index % 2 === 0;
              return (
                <TableRow
                  key={transaction.id}
                  sx={{
                    backgroundColor: isEven ? "#2f3140" : "#3d4155",
                    height: "45px",
                  }}
                >
                  <TableCell sx={{ fontFamily: "Bungee-Regular" }} align="left">
                    {transaction.type}
                  </TableCell>
                  <TableCell sx={{ fontFamily: "Bungee-Regular" }} align="left">
                    {(transaction.amount / 100).toFixed(2)}
                  </TableCell>

                  <TableCell align="left">
                    {toHumanReadableDate(transaction.date)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {_hasMore && _cursor && (
        <Button
          onClick={() => {
            setCursor(_cursor);
          }}
          fullWidth
          variant={"contained"}
          sx={{
            mt: 2,
          }}
        >
          Load More
        </Button>
      )}
    </Box>
  );
}

const toHumanReadableDate = (isoString: string) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return `${formattedDate} at ${formattedTime}`;
};
