import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  CircularProgress,
  DialogContentText,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Iconify from "../components/Iconify";
import {
  useCashoutMutation,
  useCreatePlayerEmailAndPasswordMutation,
  useTopUpBalanceMutation,
  useUpdatePlayerEmailMutation,
  useUpdatePlayerPasswordMutation,
  useUpdatePlayerPhoneNumberMutation,
  useUpdatePlayerCardSerialNumberMutation,
  useUpdatePlayerFirstNameMutation,
  useUpdatePlayerLastNameMutation,
  useUpdatePlayerMutation,
} from "../generated/graphql";
import phone from "phone";

import { invoke } from "@tauri-apps/api";
import { FileUpload } from "primereact/fileupload";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------
interface PlayerAccountProps {
  player: any;
  getUpdatedPlayer: any;
}

export default function PlayerAccount({
  player,
  getUpdatedPlayer,
}: PlayerAccountProps) {
  const { activeRoomId } = useAuth();
  // Top Up Balance
  const [showTopUpDialog, setShowTopUpDialog] = useState(false);
  const [topUpAmount, setTopUpAmount] = useState(0);
  const [showAddFundsConfirmation, setShowAddFundsConfirmation] =
    useState(false);

  const [{}, topUpBalance] = useTopUpBalanceMutation();

  const handleTopUpBalance = () => {
    if (topUpAmount <= 0) {
      toast.error("Invalid top up amount");
      return;
    }
    setShowTopUpDialog(false);

    topUpBalance({
      id: player.id,
      amount: topUpAmount * 100,
    })
      .then((res) => {
        if (res.data?.topUpBalance) {
          toast.success("Added Funds Successfully");
          getUpdatedPlayer();
        }
      })
      .catch((err) => {
        toast.error(`ERR: ${err}`);
      })
      .finally(() => {
        setTopUpAmount(0);
      });
  };

  // update password
  const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] =
    useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [{ error: updatePasswordError }, updatePassword] =
    useUpdatePlayerPasswordMutation();
  const handleUpdatePassword = () => {
    if (!oldPassword) {
      toast.error("Old password is required");
      return;
    }
    if (!newPassword) {
      toast.error("New password is required");
      return;
    }

    updatePassword({
      id: player.id,
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
      .then((res) => {
        if (res.data?.updatePlayerPassword) {
          getUpdatedPlayer();
          toast.success("Password updated successfully");
          setShowUpdatePasswordDialog(false);
          setOldPassword("");
          setNewPassword("");
        } else {
          if (res.error?.stack?.includes("Old password is incorrect")) {
            toast.error("Old password is incorrect");
          } else {
            toast.error("Password update failed");
          }
        }
      })
      .catch((err) => {
        toast.error(`ERR: ${err}`);
      });
  };

  // Cashout
  const [showCashoutDialog, setShowCashoutDialog] = useState(false);
  const [cashoutAmount, setCashoutAmount] = useState(0);
  const [{ error: cashoutError }, cashout] = useCashoutMutation();
  const handleCashout = () => {
    if (cashoutAmount <= 0) {
      toast.error("Invalid cashout amount");
      return;
    }

    if (cashoutAmount * 100 > player.cashout) {
      toast.error("Cashout amount is more than the available balance");
      return;
    }

    cashout({
      id: player.id,
      amount: cashoutAmount * 100,
    })
      .then((res) => {
        if (res.data?.cashout) {
          getUpdatedPlayer();
          toast.success("Player cashed out successfully");
          setCashoutAmount(0);
        }
      })
      .catch((err) => {
        toast.error(`ERR: ${err}`);
      })
      .finally(() => {
        setShowCashoutDialog(false);
        setCashoutAmount(0);
      });
  };

  // issue card
  const [
    { error: updatePlayerCardSerialNumberError },
    updatePlayerCardSerialNumber,
  ] = useUpdatePlayerCardSerialNumberMutation();

  const [showCardScanDialog, setShowCardScanDialog] = useState(false);
  const handleIssueCard = async (userId: string) => {
    setShowCardScanDialog(true);

    try {
      // hit the Tauri Rust function to write userId to the card
      const cardSerialNumber = await invoke(
        "plugin:tauri_plugin_nfc|issue_card",
        {
          userId,
        }
      );
      // hit GQL endpoint to save the player card serial number to DB
      await updatePlayerCardSerialNumber({
        playerId: player.id,
        cardSerialNumber: cardSerialNumber as string,
      });
      getUpdatedPlayer();
      toast.success("Issued Player Card!");
    } catch (e) {
      toast.error("Error issuing card:", e);
    }
    setShowCardScanDialog(false);
  };

  // deactivate card
  const handleDeactivateCard = async () => {
    try {
      await updatePlayerCardSerialNumber({
        playerId: player.id,
        cardSerialNumber: null,
      });
      getUpdatedPlayer();
      toast.success("Deactivated Player Card!");
    } catch (e) {
      toast.error("Error issuing card:", e);
    }
    setShowCardScanDialog(false);
  };

  // Setup Home Play Account
  const [
    { error: updatePlayerEmailAndPasswordError },
    createPlayerEmailAndPasswordMutation,
  ] = useCreatePlayerEmailAndPasswordMutation();
  const [
    showCreatePlayerEmailAndPasswordDialog,
    setShowCreatePlayerEmailAndPasswordDialog,
  ] = useState(false);
  const handleCreatePlayerEmailAndPassword = async () => {
    if (!isValidEmail(email)) {
      toast.error("Invalid email");
      return;
    }

    createPlayerEmailAndPasswordMutation({
      id: player.id,
      email: email,
      password: newPassword,
    })
      .then((res) => {
        getUpdatedPlayer();
        toast.success("Account setup successfully");
        setShowCreatePlayerEmailAndPasswordDialog(false);
      })
      .catch((err) => {
        toast.error(`ERR: ${err}`);
      })
      .finally(() => {});
  };
  // Update Player Info
  const [username, setUserName] = useState(player?.username);
  const [firstName, setFirstName] = useState(player?.firstName);
  const [lastName, setLastName] = useState(player?.lastName || "");
  const [email, setEmail] = useState(player.email);
  const [phoneNumber, setPhoneNumber] = useState(player.phoneNumber);
  const [images, setImages]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatePlayerDialogOpen, setUpdatePlayerDialogOpen] = useState(false);
  const [{ error: updatePlayerError }, updatePlayer] =
    useUpdatePlayerMutation();
  const handleUpdatePlayerDetails = () => {
    if (email && !isValidEmail(email)) {
      toast.error("Invalid email");
      return;
    }
    setLoading(true);
    updatePlayer({
      input: {
        username: username,
        playerId: player.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        images: images,
      },
    }).then((res) => {
      if (res.error) {
        toast.error(`ERR: ${res.error}`);
      } else {
        toast.success("Successfully updated");
        setUpdatePlayerDialogOpen(false);
        getUpdatedPlayer();
      }
      setLoading(false);
    });
  };

  const [cautionRoomIdMismatch, setCautionRoomIdMismatch] = useState(false);
  useEffect(() => {
    if (activeRoomId !== player.roomId) {
      setCautionRoomIdMismatch(true);
    }
  }, []);

  return (
    <Box>
      {/* Player Balance */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            backgroundColor: "#2f3140",
            p: 2,
            mx: 2,
            borderRadius: "5%",
          }}
        >
          <Typography
            variant="h4"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            Entry
          </Typography>

          <Typography
            variant="h5"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            {player.entry !== undefined
              ? (player.entry / 100).toFixed(2)
              : "N/A"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            backgroundColor: "#2f3140",
            p: 2,
            mx: 2,
            borderRadius: "5%",
          }}
        >
          <Typography
            variant="h4"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            Wininngs
          </Typography>
          <Typography
            variant="h5"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            {player.winnings !== undefined
              ? (player.winnings / 100).toFixed(2)
              : "N/A"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            backgroundColor: "#2f3140",
            p: 2,
            mx: 2,
            borderRadius: "5%",
          }}
        >
          <Typography
            variant="h4"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            Cashout
          </Typography>
          <Typography
            variant="h5"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            {player.cashout !== undefined
              ? (player.cashout / 100).toFixed(2)
              : "N/A"}
          </Typography>
        </Box>
      </Box>
      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 2,
          mt: 4,
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setShowTopUpDialog(true);
          }}
        >
          Add Funds
        </Button>

        <Button
          disabled={player.cashout === 0}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setShowCashoutDialog(true);
          }}
        >
          Cash Out
        </Button>

        <Button
          disabled={player.cardSerialNumber}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            handleIssueCard(player.id);
          }}
        >
          Issue Card
        </Button>

        <Button
          disabled={!player.cardSerialNumber}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            handleDeactivateCard();
          }}
        >
          Deactivate Card
        </Button>

        <Button
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setUpdatePlayerDialogOpen(true);
          }}
        >
          Update Player Info
        </Button>

        <Button
          disabled={!player.isPlayFromHome}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setShowUpdatePasswordDialog(true);
          }}
        >
          Update Password
        </Button>

        <Button
          disabled={player.isPlayFromHome}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setShowCreatePlayerEmailAndPasswordDialog(true);
          }}
        >
          Set-up HomePlay
        </Button>
      </Box>

      {/* Player Details */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          my: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2a3039",
            py: 4,
          }}
        >
          <Typography variant="h4" sx={{ ml: 1, flex: 1 }}>
            Player Details
          </Typography>
        </Box>
        {/* Player Id */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Player ID *
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.id}
          </Typography>
        </Box>
        {/* Player User Name */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2f3140",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Username
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.username ? player.username : "N/A"}
          </Typography>
        </Box>
        {/* Player First Name */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            First Name *
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.firstName ? player.firstName : "N/A"}
          </Typography>
        </Box>
        {/* Player Last Name */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2f3140",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Last Name
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.lastName ? player.lastName : "N/A"}
          </Typography>
        </Box>
        {/* Player Email */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Email
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.email ? player.email : "N/A"}
          </Typography>
        </Box>
        {/* Phone Number */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2f3140",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Phone Number
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.phoneNumber ? formatPhoneNumber(player.phoneNumber) : "N/A"}
          </Typography>
        </Box>
        {/* Player Password */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Password
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.isPlayFromHome ? "********" : "N/A"}
          </Typography>
        </Box>
        {/* Player Card Serial Number */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2f3140",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Card Serial Number
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.cardSerialNumber ? player.cardSerialNumber : "N/A"}
          </Typography>
        </Box>
        {/* RoomId */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Room ID *
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.roomId ? player.roomId : "N/A"}
          </Typography>
        </Box>
      </Box>

      {/* Top Up Dialog */}
      <Dialog
        onClose={() => {
          setShowTopUpDialog(false);
          setShowAddFundsConfirmation(false);
        }}
        open={showTopUpDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          <Typography variant="h4">Add Funds </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowTopUpDialog(false);
              setShowAddFundsConfirmation(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "500px",
            bgcolor: "#2e3c5a",
          }}
        >
          {showAddFundsConfirmation ? (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to add funds to the player account?
              </Typography>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "900" }}>
                Entry Amount: {!topUpAmount ? 0 : topUpAmount.toFixed(2)}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Add entry funds to the player account. Once confirmed user will
                be able to use the funds immediately
              </Typography>

              <TextField
                label="Amount"
                value={topUpAmount == 0 ? "" : topUpAmount}
                onChange={(e) => setTopUpAmount(parseFloat(e.target.value))}
                fullWidth
                type="number"
                sx={{
                  mt: 2,
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          {showAddFundsConfirmation && (
            <Button
              variant="outlined"
              autoFocus
              onClick={(e) => setShowAddFundsConfirmation(false)}
            >
              Cancel
            </Button>
          )}
          <Button
            variant="contained"
            autoFocus
            onClick={(e) => {
              if (!showAddFundsConfirmation) {
                setShowAddFundsConfirmation(true);
                return;
              }
              handleTopUpBalance();
            }}
          >
            Add Funds
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Cashout Dialog */}
      <Dialog
        onClose={() => setShowCashoutDialog(false)}
        open={showCashoutDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          <Typography variant="h4">Cashout</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setShowCashoutDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "500px",
            bgcolor: "#2e3c5a",
          }}
        >
          <Typography variant="body2" sx={{ mb: 2 }}>
            Cashout Amount must be less than or equal to the Cashout Balance
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            Cashout Balance: {(player.cashout / 100).toFixed(2)}
          </Typography>

          <TextField
            label="Cashout Amount"
            value={cashoutAmount == 0 ? "" : cashoutAmount}
            onChange={(e) => setCashoutAmount(parseFloat(e.target.value))}
            fullWidth
            type="number"
            sx={{
              mt: 2,
            }}
          />
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            variant="contained"
            autoFocus
            onClick={(e) => handleCashout()}
          >
            Cashout
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Card scan Dialog */}
      <Dialog
        open={showCardScanDialog}
        onClose={() => setShowCardScanDialog(false)}
      >
        <DialogTitle sx={{ bgcolor: "#2e3c5a" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Card Scan</Typography>
            <IconButton
              aria-label="close"
              onClick={() => setShowCardScanDialog(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon="ep:close-bold" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "#2e3c5a" }}>
          <DialogContentText id="card-scan-dialog-description">
            Tap the card to the card reader to issue player card for this player
          </DialogContentText>
          <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold" }}>
            Tap the card to the card reader now
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      {/* --------------------------------*/}
      {/* Password Update Dialog */}
      <Dialog
        onClose={() => setShowUpdatePasswordDialog(false)}
        open={showUpdatePasswordDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          Update Password
          <IconButton
            aria-label="close"
            onClick={() => setShowUpdatePasswordDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: "400px",
            minWidth: "350px",
            bgcolor: "#2e3c5a",
          }}
        >
          <FormControl variant="outlined" fullWidth sx={{ my: 2 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Old Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Old Password *"
            />
          </FormControl>

          <FormControl variant="outlined" fullWidth sx={{ my: 2 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              New Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password *"
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            variant="contained"
            autoFocus
            onClick={(e) => handleUpdatePassword()}
          >
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Setup Home Play Account Dialog */}
      <Dialog
        onClose={() => setShowCreatePlayerEmailAndPasswordDialog(false)}
        open={showCreatePlayerEmailAndPasswordDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          <Typography variant="h4"> Setup Home Play Account</Typography>

          <IconButton
            aria-label="close"
            onClick={() => setShowCreatePlayerEmailAndPasswordDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "500px",
            bgcolor: "#2e3c5a",
          }}
        >
          <TextField
            label="Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{
              mt: 2,
            }}
          />
          <FormControl variant="outlined" fullWidth sx={{ my: 2 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password *"
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            variant="contained"
            autoFocus
            onClick={(e) => handleCreatePlayerEmailAndPassword()}
          >
            Create Account
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Player Update Dialog */}
      <Dialog
        onClose={() => {
          setUpdatePlayerDialogOpen(false);
        }}
        open={updatePlayerDialogOpen}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          Edit Player Details
          <IconButton
            aria-label="close"
            onClick={() => {
              setUpdatePlayerDialogOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "600px",
            maxWidth: "600px",
            bgcolor: "#2e3c5a",
          }}
        >
          <TextField
            label="Username"
            variant="outlined"
            sx={{ mt: 2 }}
            fullWidth
            defaultValue={player.username}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />

          <TextField
            label="First Name"
            variant="outlined"
            sx={{ my: 2 }}
            fullWidth
            defaultValue={player.firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            sx={{ mb: 2 }}
            fullWidth
            defaultValue={player.lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />

          {player.isPlayFromHome && (
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              defaultValue={player.email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          )}
          <TextField
            label="Phone Number"
            variant="outlined"
            sx={{ mt: 2 }}
            fullWidth
            defaultValue={formatPhoneNumber(player.phoneNumber)}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />

          {/* Upload --- FEATURE OFF */}
          {/* <Box sx={{ mt: 2 }}>
            <FileUpload
              style={{
                maxHeight: "150px",
                overflow: "auto ",
              }}
              name="FileUploader[]"
              multiple
              accept="image/*"
              maxFileSize={1000000}
              customUpload
              itemTemplate={(file: any) => {
                return (
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <img
                      alt={file.name}
                      role="presentation"
                      src={file.objectURL}
                      width={50}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      {file.name} {"-"}
                    </span>
                    <span style={{ marginLeft: "10px" }}>
                      {file.size} bytes
                    </span>
                  </Box>
                );
              }}
              uploadHandler={(e) => {
                setImages(e.files);
              }}
              onClear={() => {
                setImages([]);
              }}
              emptyTemplate={
                <p style={{ marginTop: "10px" }}>
                  Drag and drop files to here to upload.
                </p>
              }
            />
          </Box> */}
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            disabled={loading}
            variant="contained"
            autoFocus
            onClick={() => {
              handleUpdatePlayerDetails();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Player roomId doesn't match active room Id Dialog */}
      <Dialog
        onClose={() => {
          setCautionRoomIdMismatch(false);
        }}
        open={cautionRoomIdMismatch}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          Caution - Player Room ID Mismatch
          <IconButton
            aria-label="close"
            onClick={() => {
              setCautionRoomIdMismatch(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "600px",
            maxWidth: "600px",
            bgcolor: "#2e3c5a",
          }}
        >
          <Typography variant="body1" sx={{ mt: 2 }}>
            The player's room ID doesn't match the active room ID. Please make
            sure the player is in the correct room.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            disabled={loading}
            variant="contained"
            autoFocus
            onClick={() => {
              setCautionRoomIdMismatch(false);
            }}
          >
            I Understand
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function isValidEmail(email: string) {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
}

function formatPhoneNumber(phoneNumber: any) {
  // Step 2: Sanitize the input to remove non-numeric characters
  const sanitizedNumber = phoneNumber.replace(/\D/g, "");

  // Step 3: Check the length of the sanitized number to decide on the formatting pattern
  // Assuming a US phone number format for this example
  if (sanitizedNumber.length === 10) {
    // Step 4: Format the number
    return `(${sanitizedNumber.substring(0, 3)}) ${sanitizedNumber.substring(
      3,
      6
    )}-${sanitizedNumber.substring(6)}`;
  } else if (sanitizedNumber.length > 10) {
    // Assuming the first part is the country code
    const countryCode = sanitizedNumber.substring(
      0,
      sanitizedNumber.length - 10
    );
    const mainNumber = sanitizedNumber.substring(sanitizedNumber.length - 10);
    return `+${countryCode} (${mainNumber.substring(
      0,
      3
    )}) ${mainNumber.substring(3, 6)}-${mainNumber.substring(6)}`;
  } else {
    // If the number doesn't match expected lengths, return it as is or handle accordingly
    return phoneNumber;
  }
}
