import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  File: { input: any; output: any; }
  NonNegativeInt: { input: any; output: any; }
  PositiveInt: { input: any; output: any; }
};

export type Admin = {
  __typename?: 'Admin';
  adminRoleName?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AdminLog = {
  __typename?: 'AdminLog';
  adminId: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type AdminLogPagination = {
  __typename?: 'AdminLogPagination';
  adminLogs: Array<AdminLog>;
  cursor?: Maybe<Scalars['Int']['output']>;
  hasMore: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AdminPagination = {
  __typename?: 'AdminPagination';
  admins: Array<Admin>;
  cursor?: Maybe<Scalars['String']['output']>;
  hasMore: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AdminWithRooms = {
  __typename?: 'AdminWithRooms';
  adminRoleName?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roomIds: Array<Scalars['String']['output']>;
};

export type BetDataPoint = {
  __typename?: 'BetDataPoint';
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type BetDataPoints = {
  __typename?: 'BetDataPoints';
  dataPoints: Array<BetDataPoint>;
  numberOfBets: Scalars['Int']['output'];
  total: Scalars['Float']['output'];
};

export type CreateAdminInput = {
  adminRoleName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGameInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  gameCategory: Scalars['String']['input'];
  imgUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePlayerInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  playFromHome?: InputMaybe<Scalars['Boolean']['input']>;
  roomId: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRoomInput = {
  ggrBps?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type DataPoint = {
  __typename?: 'DataPoint';
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type DataPoints = {
  __typename?: 'DataPoints';
  dataPoints: Array<DataPoint>;
  total: Scalars['Float']['output'];
};

export enum Frequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type Game = {
  __typename?: 'Game';
  displayName?: Maybe<Scalars['String']['output']>;
  gameCategory: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imgUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type GameSession = {
  __typename?: 'GameSession';
  bonus?: Maybe<Scalars['Int']['output']>;
  difficulty?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  entry?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isSessionActive?: Maybe<Scalars['Boolean']['output']>;
  playerId: Scalars['String']['output'];
  requestCount?: Maybe<Scalars['Int']['output']>;
  seed?: Maybe<Scalars['String']['output']>;
  seedIndex?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['String']['output'];
  startingEntry?: Maybe<Scalars['Int']['output']>;
  startingWinnings?: Maybe<Scalars['Int']['output']>;
  stationId: Scalars['String']['output'];
  winnings?: Maybe<Scalars['Int']['output']>;
};

export type IntegerCursorPagination = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  attachRoom: AdminWithRooms;
  cashout: Player;
  createAdmin: Admin;
  createDiscordChannel: Scalars['String']['output'];
  createGame: Game;
  createPlayer: Player;
  createPlayerEmailAndPassword: Scalars['Boolean']['output'];
  createRoom: Room;
  createStation: StationWithSecret;
  detachRoom: AdminWithRooms;
  resetStationSecret: StationWithSecret;
  toggleAdmin: Admin;
  toggleStation: Station;
  topUpBalance: Player;
  updateAdmin: Admin;
  updateGame: Game;
  updatePassword: Admin;
  updatePlayer: Player;
  updatePlayerCardSerialNumber: Scalars['Boolean']['output'];
  updatePlayerCashout: Scalars['Boolean']['output'];
  updatePlayerEmail: Scalars['Boolean']['output'];
  updatePlayerEntry: Scalars['Boolean']['output'];
  updatePlayerFirstName: Scalars['Boolean']['output'];
  updatePlayerFullName: Scalars['Boolean']['output'];
  updatePlayerLastName: Scalars['Boolean']['output'];
  updatePlayerPassword: Scalars['Boolean']['output'];
  updatePlayerPhoneNumber: Scalars['Boolean']['output'];
  updatePlayerWinnings: Scalars['Boolean']['output'];
  updateRoom: Room;
  updateRoomBps: Scalars['Boolean']['output'];
  updateStation: Station;
};


export type MutationAttachRoomArgs = {
  id: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationCashoutArgs = {
  amount: Scalars['Int']['input'];
  id: Scalars['String']['input'];
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateGameArgs = {
  input: CreateGameInput;
};


export type MutationCreatePlayerArgs = {
  input: CreatePlayerInput;
};


export type MutationCreatePlayerEmailAndPasswordArgs = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCreateRoomArgs = {
  input: CreateRoomInput;
};


export type MutationCreateStationArgs = {
  roomId: Scalars['String']['input'];
};


export type MutationDetachRoomArgs = {
  id: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationResetStationSecretArgs = {
  stationId: Scalars['String']['input'];
};


export type MutationToggleAdminArgs = {
  id: Scalars['String']['input'];
};


export type MutationToggleStationArgs = {
  id: Scalars['String']['input'];
};


export type MutationTopUpBalanceArgs = {
  amount: Scalars['Int']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};


export type MutationUpdatePasswordArgs = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdatePlayerArgs = {
  input: UpdatePlayerInput;
};


export type MutationUpdatePlayerCardSerialNumberArgs = {
  cardSerialNumber?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['String']['input'];
};


export type MutationUpdatePlayerCashoutArgs = {
  cashout: Scalars['Int']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdatePlayerEmailArgs = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdatePlayerEntryArgs = {
  entry: Scalars['Int']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdatePlayerFirstNameArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdatePlayerFullNameArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUpdatePlayerLastNameArgs = {
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUpdatePlayerPasswordArgs = {
  id: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationUpdatePlayerPhoneNumberArgs = {
  id: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationUpdatePlayerWinningsArgs = {
  id: Scalars['String']['input'];
  winnings: Scalars['Int']['input'];
};


export type MutationUpdateRoomArgs = {
  input: UpdateRoomInput;
};


export type MutationUpdateRoomBpsArgs = {
  bps: Scalars['NonNegativeInt']['input'];
  roomId: Scalars['String']['input'];
};


export type MutationUpdateStationArgs = {
  input: UpdateStationInput;
};

export type Player = {
  __typename?: 'Player';
  cardSerialNumber?: Maybe<Scalars['String']['output']>;
  cashout: Scalars['Int']['output'];
  email?: Maybe<Scalars['String']['output']>;
  entry: Scalars['Int']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isPlayFromHome: Scalars['Boolean']['output'];
  isSessionActive?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  room?: Maybe<Room>;
  roomId: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
  winnings: Scalars['Int']['output'];
};

export type PlayersQuery = {
  __typename?: 'PlayersQuery';
  cursor?: Maybe<Scalars['String']['output']>;
  hasMore: Scalars['Boolean']['output'];
  players: Array<Player>;
};

export type Query = {
  __typename?: 'Query';
  countStationsByRoomId: Scalars['Int']['output'];
  countTransactionsByRoomId: Scalars['Int']['output'];
  getAdmin: Admin;
  getAdminById: Admin;
  getAdminList: AdminPagination;
  getAdminLogListByAdminId: AdminLogPagination;
  getAllTransactions: TransactionPagination;
  getAverageBetByGameName: DataPoints;
  getAverageBetByPlayerId: DataPoints;
  getAverageSessionDurationByPlayerId: Scalars['Float']['output'];
  getBetsByGameName: BetDataPoints;
  getBetsByRoomId: BetDataPoints;
  getBetsByStationId: BetDataPoints;
  getGGRByAdminId: DataPoints;
  getGGRByPlayerId: DataPoints;
  getGGRByRoomId: DataPoints;
  getGameList: Array<Game>;
  getOperatorById: Admin;
  getOperatorList: AdminPagination;
  getPersonaList: Array<Scalars['String']['output']>;
  getPersonaListBelow: Array<Scalars['String']['output']>;
  getPlayerById: Player;
  getPlayerByIdOrCardSerialNumber: Player;
  getPlayerList: PlayersQuery;
  getPlayerListByRoomId: PlayersQuery;
  getRoomById: Room;
  getRoomGGRBps: Scalars['Int']['output'];
  getRoomList: Array<Room>;
  getRoomListByAdminId: Array<Room>;
  getRoomPayToProvider?: Maybe<Scalars['NonNegativeInt']['output']>;
  getSalesRepList: AdminPagination;
  getStationActiveVersionInstaller: Scalars['String']['output'];
  getStationAppActiveVersion: Scalars['String']['output'];
  getStationById: Station;
  getStationList: Array<Maybe<Station>>;
  getStationListByRoomId: Array<Maybe<Station>>;
  getStationsForOperator: Array<Station>;
  getSuperAdminList: AdminPagination;
  getTransactionList: TransactionPagination;
  getTransactionListByPlayerId: TransactionPagination;
  getTransactionListByRoomId: TransactionPagination;
  issueCardValidation: Scalars['Boolean']['output'];
  searchPlayers: PlayersQuery;
};


export type QueryCountStationsByRoomIdArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryCountTransactionsByRoomIdArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryGetAdminByIdArgs = {
  adminId: Scalars['String']['input'];
};


export type QueryGetAdminListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdminLogListByAdminIdArgs = {
  adminId: Scalars['String']['input'];
  pagination?: InputMaybe<IntegerCursorPagination>;
};


export type QueryGetAverageBetByGameNameArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  gameName: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryGetAverageBetByPlayerIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  playerId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryGetAverageSessionDurationByPlayerIdArgs = {
  playerId: Scalars['String']['input'];
};


export type QueryGetBetsByGameNameArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  gameName: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryGetBetsByRoomIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  roomId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryGetBetsByStationIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  stationId: Scalars['String']['input'];
};


export type QueryGetGgrByAdminIdArgs = {
  adminId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  startDate: Scalars['DateTime']['input'];
};


export type QueryGetGgrByPlayerIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  playerId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryGetGgrByRoomIdArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  frequency: Frequency;
  roomId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryGetOperatorByIdArgs = {
  adminId: Scalars['String']['input'];
};


export type QueryGetOperatorListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPlayerByIdArgs = {
  playerId: Scalars['String']['input'];
};


export type QueryGetPlayerByIdOrCardSerialNumberArgs = {
  playerIdOrCardSerialNumber: Scalars['String']['input'];
};


export type QueryGetPlayerListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
};


export type QueryGetPlayerListByRoomIdArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  roomId: Scalars['String']['input'];
};


export type QueryGetRoomByIdArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryGetRoomGgrBpsArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryGetRoomListByAdminIdArgs = {
  adminId: Scalars['String']['input'];
};


export type QueryGetRoomPayToProviderArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  roomId: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryGetSalesRepListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetStationByIdArgs = {
  stationId: Scalars['String']['input'];
};


export type QueryGetStationListByRoomIdArgs = {
  roomId: Scalars['String']['input'];
};


export type QueryGetStationsForOperatorArgs = {
  adminId: Scalars['String']['input'];
};


export type QueryGetSuperAdminListArgs = {
  pagination?: InputMaybe<StringCursorPagination>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTransactionListArgs = {
  filter?: InputMaybe<GetTransactionListFilter>;
  pagination?: InputMaybe<IntegerCursorPagination>;
};


export type QueryGetTransactionListByPlayerIdArgs = {
  pagination?: InputMaybe<IntegerCursorPagination>;
  playerId: Scalars['String']['input'];
};


export type QueryGetTransactionListByRoomIdArgs = {
  pagination?: InputMaybe<IntegerCursorPagination>;
  roomId: Scalars['String']['input'];
};


export type QueryIssueCardValidationArgs = {
  playerId: Scalars['String']['input'];
};


export type QuerySearchPlayersArgs = {
  input: SearchPlayersInput;
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['String']['output'];
  isReadyForUpdate?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['String']['output']>;
};

export type SearchPlayersInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['PositiveInt']['input']>;
  query: Scalars['String']['input'];
  roomId?: InputMaybe<Scalars['String']['input']>;
};

export type Station = {
  __typename?: 'Station';
  createdAt?: Maybe<Scalars['String']['output']>;
  gameSessionList?: Maybe<Array<Maybe<GameSession>>>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  roomId: Scalars['String']['output'];
  stationAppVersion?: Maybe<Scalars['String']['output']>;
};

export type StationWithSecret = {
  __typename?: 'StationWithSecret';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  roomId: Scalars['String']['output'];
  secret?: Maybe<Scalars['String']['output']>;
};

export type StringCursorPagination = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  adminId: Scalars['String']['output'];
  amount: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  playerId: Scalars['String']['output'];
  roomId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type TransactionPagination = {
  __typename?: 'TransactionPagination';
  cursor?: Maybe<Scalars['Int']['output']>;
  hasMore: Scalars['Boolean']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
  transactions: Array<Transaction>;
};

export enum TransactionType {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW'
}

export type UpdateAdminInput = {
  adminRoleName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGameInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  gameCategory?: InputMaybe<Scalars['String']['input']>;
  gameId: Scalars['Int']['input'];
  imgUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlayerInput = {
  cashout?: InputMaybe<Scalars['PositiveInt']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entry?: InputMaybe<Scalars['PositiveInt']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['String']['input'];
  roomId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  winnings?: InputMaybe<Scalars['PositiveInt']['input']>;
};

export type UpdateRoomInput = {
  ggrBps?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  isReadyForUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId: Scalars['String']['input'];
};

export type UpdateStationInput = {
  certificateFingerprint?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  stationId: Scalars['String']['input'];
};

export type GetTransactionListFilter = {
  type?: InputMaybe<TransactionType>;
};

export type CreateDiscordChannelMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateDiscordChannelMutation = { __typename?: 'Mutation', createDiscordChannel: string };

export type GetRoomListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoomListQuery = { __typename?: 'Query', getRoomList: Array<{ __typename?: 'Room', id: string, name: string, location?: string | null, isReadyForUpdate?: boolean | null }> };

export type GetTransactionListByRoomIdQueryVariables = Exact<{
  roomId: Scalars['String']['input'];
  pagination?: InputMaybe<IntegerCursorPagination>;
}>;


export type GetTransactionListByRoomIdQuery = { __typename?: 'Query', getTransactionListByRoomId: { __typename?: 'TransactionPagination', cursor?: number | null, hasMore: boolean, totalCount?: number | null, transactions: Array<{ __typename?: 'Transaction', id: number, playerId: string, adminId: string, roomId: string, amount: number, type: string, date: string }> } };

export type GetTransactionListByPlayerIdQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
  pagination?: InputMaybe<IntegerCursorPagination>;
}>;


export type GetTransactionListByPlayerIdQuery = { __typename?: 'Query', getTransactionListByPlayerId: { __typename?: 'TransactionPagination', cursor?: number | null, hasMore: boolean, totalCount?: number | null, transactions: Array<{ __typename?: 'Transaction', id: number, playerId: string, adminId: string, roomId: string, amount: number, type: string, date: string }> } };

export type IssueCardValidationQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type IssueCardValidationQuery = { __typename?: 'Query', issueCardValidation: boolean };

export type GetPlayerListQueryVariables = Exact<{
  pagination?: InputMaybe<StringCursorPagination>;
}>;


export type GetPlayerListQuery = { __typename?: 'Query', getPlayerList: { __typename?: 'PlayersQuery', cursor?: string | null, hasMore: boolean, players: Array<{ __typename?: 'Player', id: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, entry: number, winnings: number, cashout: number, isPlayFromHome: boolean, cardSerialNumber?: string | null }> } };

export type GetPlayerListByRoomIdQueryVariables = Exact<{
  roomId: Scalars['String']['input'];
  pagination?: InputMaybe<StringCursorPagination>;
}>;


export type GetPlayerListByRoomIdQuery = { __typename?: 'Query', getPlayerListByRoomId: { __typename?: 'PlayersQuery', cursor?: string | null, hasMore: boolean, players: Array<{ __typename?: 'Player', id: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, entry: number, winnings: number, cashout: number, isPlayFromHome: boolean, cardSerialNumber?: string | null }> } };

export type GetPlayerByIdOrCardSerialNumberQueryVariables = Exact<{
  playerIdOrCardSerialNumber: Scalars['String']['input'];
}>;


export type GetPlayerByIdOrCardSerialNumberQuery = { __typename?: 'Query', getPlayerByIdOrCardSerialNumber: { __typename?: 'Player', id: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, entry: number, winnings: number, cashout: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, roomId: string } };

export type GetPlayerByIdQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type GetPlayerByIdQuery = { __typename?: 'Query', getPlayerById: { __typename?: 'Player', id: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, entry: number, winnings: number, cashout: number, isPlayFromHome: boolean, cardSerialNumber?: string | null, roomId: string, images?: Array<string | null> | null } };

export type GetAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminQuery = { __typename?: 'Query', getAdmin: { __typename?: 'Admin', id: string, name?: string | null, isActive?: boolean | null, adminRoleName?: string | null } };

export type GetSuperAdminListQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<StringCursorPagination>;
}>;


export type GetSuperAdminListQuery = { __typename?: 'Query', getSuperAdminList: { __typename?: 'AdminPagination', cursor?: string | null, hasMore: boolean, totalCount: number, admins: Array<{ __typename?: 'Admin', id: string, name?: string | null, isActive?: boolean | null, adminRoleName?: string | null }> } };

export type GetStationListByRoomIdQueryVariables = Exact<{
  roomId: Scalars['String']['input'];
}>;


export type GetStationListByRoomIdQuery = { __typename?: 'Query', getStationListByRoomId: Array<{ __typename?: 'Station', id: string, roomId: string, isActive?: boolean | null, stationAppVersion?: string | null, createdAt?: string | null, gameSessionList?: Array<{ __typename?: 'GameSession', id: string, playerId: string, stationId: string, startDate: string, endDate?: string | null, startingEntry?: number | null, startingWinnings?: number | null, entry?: number | null, winnings?: number | null, requestCount?: number | null, seed?: string | null, seedIndex?: number | null, bonus?: number | null, difficulty?: number | null, isSessionActive?: boolean | null } | null> | null } | null> };

export type GetStationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStationListQuery = { __typename?: 'Query', getStationList: Array<{ __typename?: 'Station', id: string, roomId: string, isActive?: boolean | null, stationAppVersion?: string | null, createdAt?: string | null, gameSessionList?: Array<{ __typename?: 'GameSession', id: string, playerId: string, stationId: string, startDate: string, endDate?: string | null, startingEntry?: number | null, startingWinnings?: number | null, entry?: number | null, winnings?: number | null, requestCount?: number | null, seed?: string | null, seedIndex?: number | null, bonus?: number | null, difficulty?: number | null, isSessionActive?: boolean | null } | null> | null } | null> };

export type GetStationAppActiveVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStationAppActiveVersionQuery = { __typename?: 'Query', getStationAppActiveVersion: string };

export type SearchPlayersQueryVariables = Exact<{
  input: SearchPlayersInput;
}>;


export type SearchPlayersQuery = { __typename?: 'Query', searchPlayers: { __typename?: 'PlayersQuery', cursor?: string | null, hasMore: boolean, players: Array<{ __typename?: 'Player', id: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, entry: number, winnings: number, cashout: number }> } };

export type CreatePlayerMutationVariables = Exact<{
  input: CreatePlayerInput;
}>;


export type CreatePlayerMutation = { __typename?: 'Mutation', createPlayer: { __typename?: 'Player', id: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, entry: number, winnings: number } };

export type TopUpBalanceMutationVariables = Exact<{
  id: Scalars['String']['input'];
  amount: Scalars['Int']['input'];
}>;


export type TopUpBalanceMutation = { __typename?: 'Mutation', topUpBalance: { __typename?: 'Player', id: string, firstName: string, lastName: string, email?: string | null, entry: number, winnings: number } };

export type CashoutMutationVariables = Exact<{
  id: Scalars['String']['input'];
  amount: Scalars['Int']['input'];
}>;


export type CashoutMutation = { __typename?: 'Mutation', cashout: { __typename?: 'Player', id: string, firstName: string, lastName: string, email?: string | null, entry: number, winnings: number } };

export type UpdatePlayerMutationVariables = Exact<{
  input: UpdatePlayerInput;
}>;


export type UpdatePlayerMutation = { __typename?: 'Mutation', updatePlayer: { __typename?: 'Player', id: string, username?: string | null, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, entry: number, winnings: number } };

export type UpdatePlayerPhoneNumberMutationVariables = Exact<{
  id: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}>;


export type UpdatePlayerPhoneNumberMutation = { __typename?: 'Mutation', updatePlayerPhoneNumber: boolean };

export type UpdatePlayerEmailMutationVariables = Exact<{
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type UpdatePlayerEmailMutation = { __typename?: 'Mutation', updatePlayerEmail: boolean };

export type UpdatePlayerCardSerialNumberMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
  cardSerialNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdatePlayerCardSerialNumberMutation = { __typename?: 'Mutation', updatePlayerCardSerialNumber: boolean };

export type UpdatePlayerPasswordMutationVariables = Exact<{
  id: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UpdatePlayerPasswordMutation = { __typename?: 'Mutation', updatePlayerPassword: boolean };

export type UpdatePlayerFullNameMutationVariables = Exact<{
  id: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
}>;


export type UpdatePlayerFullNameMutation = { __typename?: 'Mutation', updatePlayerFullName: boolean };

export type UpdatePlayerFirstNameMutationVariables = Exact<{
  id: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
}>;


export type UpdatePlayerFirstNameMutation = { __typename?: 'Mutation', updatePlayerFirstName: boolean };

export type UpdatePlayerLastNameMutationVariables = Exact<{
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
}>;


export type UpdatePlayerLastNameMutation = { __typename?: 'Mutation', updatePlayerLastName: boolean };

export type UpdatePlayerEntryMutationVariables = Exact<{
  id: Scalars['String']['input'];
  entry: Scalars['Int']['input'];
}>;


export type UpdatePlayerEntryMutation = { __typename?: 'Mutation', updatePlayerEntry: boolean };

export type UpdatePlayerWinningsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  winnings: Scalars['Int']['input'];
}>;


export type UpdatePlayerWinningsMutation = { __typename?: 'Mutation', updatePlayerWinnings: boolean };

export type UpdatePlayerCashoutMutationVariables = Exact<{
  id: Scalars['String']['input'];
  cashout: Scalars['Int']['input'];
}>;


export type UpdatePlayerCashoutMutation = { __typename?: 'Mutation', updatePlayerCashout: boolean };

export type CreatePlayerEmailAndPasswordMutationVariables = Exact<{
  id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type CreatePlayerEmailAndPasswordMutation = { __typename?: 'Mutation', createPlayerEmailAndPassword: boolean };

export type UpdateRoomMutationVariables = Exact<{
  input: UpdateRoomInput;
}>;


export type UpdateRoomMutation = { __typename?: 'Mutation', updateRoom: { __typename?: 'Room', id: string, name: string, location?: string | null } };


export const CreateDiscordChannelDocument = gql`
    mutation createDiscordChannel {
  createDiscordChannel
}
    `;

export function useCreateDiscordChannelMutation() {
  return Urql.useMutation<CreateDiscordChannelMutation, CreateDiscordChannelMutationVariables>(CreateDiscordChannelDocument);
};
export const GetRoomListDocument = gql`
    query getRoomList {
  getRoomList {
    id
    name
    location
    isReadyForUpdate
  }
}
    `;

export function useGetRoomListQuery(options?: Omit<Urql.UseQueryArgs<GetRoomListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRoomListQuery, GetRoomListQueryVariables>({ query: GetRoomListDocument, ...options });
};
export const GetTransactionListByRoomIdDocument = gql`
    query getTransactionListByRoomId($roomId: String!, $pagination: IntegerCursorPagination) {
  getTransactionListByRoomId(roomId: $roomId, pagination: $pagination) {
    transactions {
      id
      playerId
      adminId
      roomId
      amount
      type
      date
    }
    cursor
    hasMore
    totalCount
  }
}
    `;

export function useGetTransactionListByRoomIdQuery(options: Omit<Urql.UseQueryArgs<GetTransactionListByRoomIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTransactionListByRoomIdQuery, GetTransactionListByRoomIdQueryVariables>({ query: GetTransactionListByRoomIdDocument, ...options });
};
export const GetTransactionListByPlayerIdDocument = gql`
    query getTransactionListByPlayerId($playerId: String!, $pagination: IntegerCursorPagination) {
  getTransactionListByPlayerId(playerId: $playerId, pagination: $pagination) {
    transactions {
      id
      playerId
      adminId
      roomId
      amount
      type
      date
    }
    cursor
    hasMore
    totalCount
  }
}
    `;

export function useGetTransactionListByPlayerIdQuery(options: Omit<Urql.UseQueryArgs<GetTransactionListByPlayerIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTransactionListByPlayerIdQuery, GetTransactionListByPlayerIdQueryVariables>({ query: GetTransactionListByPlayerIdDocument, ...options });
};
export const IssueCardValidationDocument = gql`
    query issueCardValidation($playerId: String!) {
  issueCardValidation(playerId: $playerId)
}
    `;

export function useIssueCardValidationQuery(options: Omit<Urql.UseQueryArgs<IssueCardValidationQueryVariables>, 'query'>) {
  return Urql.useQuery<IssueCardValidationQuery, IssueCardValidationQueryVariables>({ query: IssueCardValidationDocument, ...options });
};
export const GetPlayerListDocument = gql`
    query getPlayerList($pagination: StringCursorPagination) {
  getPlayerList(pagination: $pagination) {
    players {
      id
      username
      firstName
      lastName
      phoneNumber
      email
      entry
      winnings
      cashout
      isPlayFromHome
      cardSerialNumber
    }
    cursor
    hasMore
  }
}
    `;

export function useGetPlayerListQuery(options?: Omit<Urql.UseQueryArgs<GetPlayerListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayerListQuery, GetPlayerListQueryVariables>({ query: GetPlayerListDocument, ...options });
};
export const GetPlayerListByRoomIdDocument = gql`
    query getPlayerListByRoomId($roomId: String!, $pagination: StringCursorPagination) {
  getPlayerListByRoomId(roomId: $roomId, pagination: $pagination) {
    players {
      id
      username
      firstName
      lastName
      phoneNumber
      email
      entry
      winnings
      cashout
      isPlayFromHome
      cardSerialNumber
    }
    cursor
    hasMore
  }
}
    `;

export function useGetPlayerListByRoomIdQuery(options: Omit<Urql.UseQueryArgs<GetPlayerListByRoomIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayerListByRoomIdQuery, GetPlayerListByRoomIdQueryVariables>({ query: GetPlayerListByRoomIdDocument, ...options });
};
export const GetPlayerByIdOrCardSerialNumberDocument = gql`
    query getPlayerByIdOrCardSerialNumber($playerIdOrCardSerialNumber: String!) {
  getPlayerByIdOrCardSerialNumber(
    playerIdOrCardSerialNumber: $playerIdOrCardSerialNumber
  ) {
    id
    username
    firstName
    lastName
    phoneNumber
    email
    entry
    winnings
    cashout
    isPlayFromHome
    cardSerialNumber
    roomId
  }
}
    `;

export function useGetPlayerByIdOrCardSerialNumberQuery(options: Omit<Urql.UseQueryArgs<GetPlayerByIdOrCardSerialNumberQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayerByIdOrCardSerialNumberQuery, GetPlayerByIdOrCardSerialNumberQueryVariables>({ query: GetPlayerByIdOrCardSerialNumberDocument, ...options });
};
export const GetPlayerByIdDocument = gql`
    query getPlayerById($playerId: String!) {
  getPlayerById(playerId: $playerId) {
    id
    username
    firstName
    lastName
    phoneNumber
    email
    entry
    winnings
    cashout
    isPlayFromHome
    cardSerialNumber
    roomId
    images
  }
}
    `;

export function useGetPlayerByIdQuery(options: Omit<Urql.UseQueryArgs<GetPlayerByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPlayerByIdQuery, GetPlayerByIdQueryVariables>({ query: GetPlayerByIdDocument, ...options });
};
export const GetAdminDocument = gql`
    query getAdmin {
  getAdmin {
    id
    name
    isActive
    adminRoleName
  }
}
    `;

export function useGetAdminQuery(options?: Omit<Urql.UseQueryArgs<GetAdminQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAdminQuery, GetAdminQueryVariables>({ query: GetAdminDocument, ...options });
};
export const GetSuperAdminListDocument = gql`
    query getSuperAdminList($search: String, $pagination: StringCursorPagination) {
  getSuperAdminList(search: $search, pagination: $pagination) {
    admins {
      id
      name
      isActive
      adminRoleName
    }
    cursor
    hasMore
    totalCount
  }
}
    `;

export function useGetSuperAdminListQuery(options?: Omit<Urql.UseQueryArgs<GetSuperAdminListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSuperAdminListQuery, GetSuperAdminListQueryVariables>({ query: GetSuperAdminListDocument, ...options });
};
export const GetStationListByRoomIdDocument = gql`
    query getStationListByRoomId($roomId: String!) {
  getStationListByRoomId(roomId: $roomId) {
    id
    roomId
    isActive
    stationAppVersion
    createdAt
    gameSessionList {
      id
      playerId
      stationId
      startDate
      endDate
      startingEntry
      startingWinnings
      entry
      winnings
      requestCount
      seed
      seedIndex
      bonus
      difficulty
      isSessionActive
    }
  }
}
    `;

export function useGetStationListByRoomIdQuery(options: Omit<Urql.UseQueryArgs<GetStationListByRoomIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationListByRoomIdQuery, GetStationListByRoomIdQueryVariables>({ query: GetStationListByRoomIdDocument, ...options });
};
export const GetStationListDocument = gql`
    query getStationList {
  getStationList {
    id
    roomId
    isActive
    stationAppVersion
    createdAt
    gameSessionList {
      id
      playerId
      stationId
      startDate
      endDate
      startingEntry
      startingWinnings
      entry
      winnings
      requestCount
      seed
      seedIndex
      bonus
      difficulty
      isSessionActive
    }
  }
}
    `;

export function useGetStationListQuery(options?: Omit<Urql.UseQueryArgs<GetStationListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationListQuery, GetStationListQueryVariables>({ query: GetStationListDocument, ...options });
};
export const GetStationAppActiveVersionDocument = gql`
    query getStationAppActiveVersion {
  getStationAppActiveVersion
}
    `;

export function useGetStationAppActiveVersionQuery(options?: Omit<Urql.UseQueryArgs<GetStationAppActiveVersionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationAppActiveVersionQuery, GetStationAppActiveVersionQueryVariables>({ query: GetStationAppActiveVersionDocument, ...options });
};
export const SearchPlayersDocument = gql`
    query searchPlayers($input: SearchPlayersInput!) {
  searchPlayers(input: $input) {
    players {
      id
      username
      firstName
      lastName
      phoneNumber
      email
      entry
      winnings
      cashout
    }
    cursor
    hasMore
  }
}
    `;

export function useSearchPlayersQuery(options: Omit<Urql.UseQueryArgs<SearchPlayersQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchPlayersQuery, SearchPlayersQueryVariables>({ query: SearchPlayersDocument, ...options });
};
export const CreatePlayerDocument = gql`
    mutation createPlayer($input: CreatePlayerInput!) {
  createPlayer(input: $input) {
    id
    username
    firstName
    lastName
    phoneNumber
    email
    entry
    winnings
  }
}
    `;

export function useCreatePlayerMutation() {
  return Urql.useMutation<CreatePlayerMutation, CreatePlayerMutationVariables>(CreatePlayerDocument);
};
export const TopUpBalanceDocument = gql`
    mutation topUpBalance($id: String!, $amount: Int!) {
  topUpBalance(id: $id, amount: $amount) {
    id
    firstName
    lastName
    email
    entry
    winnings
  }
}
    `;

export function useTopUpBalanceMutation() {
  return Urql.useMutation<TopUpBalanceMutation, TopUpBalanceMutationVariables>(TopUpBalanceDocument);
};
export const CashoutDocument = gql`
    mutation cashout($id: String!, $amount: Int!) {
  cashout(id: $id, amount: $amount) {
    id
    firstName
    lastName
    email
    entry
    winnings
  }
}
    `;

export function useCashoutMutation() {
  return Urql.useMutation<CashoutMutation, CashoutMutationVariables>(CashoutDocument);
};
export const UpdatePlayerDocument = gql`
    mutation updatePlayer($input: UpdatePlayerInput!) {
  updatePlayer(input: $input) {
    id
    username
    firstName
    lastName
    phoneNumber
    email
    entry
    winnings
  }
}
    `;

export function useUpdatePlayerMutation() {
  return Urql.useMutation<UpdatePlayerMutation, UpdatePlayerMutationVariables>(UpdatePlayerDocument);
};
export const UpdatePlayerPhoneNumberDocument = gql`
    mutation updatePlayerPhoneNumber($id: String!, $phoneNumber: String!) {
  updatePlayerPhoneNumber(id: $id, phoneNumber: $phoneNumber)
}
    `;

export function useUpdatePlayerPhoneNumberMutation() {
  return Urql.useMutation<UpdatePlayerPhoneNumberMutation, UpdatePlayerPhoneNumberMutationVariables>(UpdatePlayerPhoneNumberDocument);
};
export const UpdatePlayerEmailDocument = gql`
    mutation updatePlayerEmail($id: String!, $email: String!) {
  updatePlayerEmail(id: $id, email: $email)
}
    `;

export function useUpdatePlayerEmailMutation() {
  return Urql.useMutation<UpdatePlayerEmailMutation, UpdatePlayerEmailMutationVariables>(UpdatePlayerEmailDocument);
};
export const UpdatePlayerCardSerialNumberDocument = gql`
    mutation updatePlayerCardSerialNumber($playerId: String!, $cardSerialNumber: String) {
  updatePlayerCardSerialNumber(
    playerId: $playerId
    cardSerialNumber: $cardSerialNumber
  )
}
    `;

export function useUpdatePlayerCardSerialNumberMutation() {
  return Urql.useMutation<UpdatePlayerCardSerialNumberMutation, UpdatePlayerCardSerialNumberMutationVariables>(UpdatePlayerCardSerialNumberDocument);
};
export const UpdatePlayerPasswordDocument = gql`
    mutation updatePlayerPassword($id: String!, $oldPassword: String!, $newPassword: String!) {
  updatePlayerPassword(
    id: $id
    oldPassword: $oldPassword
    newPassword: $newPassword
  )
}
    `;

export function useUpdatePlayerPasswordMutation() {
  return Urql.useMutation<UpdatePlayerPasswordMutation, UpdatePlayerPasswordMutationVariables>(UpdatePlayerPasswordDocument);
};
export const UpdatePlayerFullNameDocument = gql`
    mutation updatePlayerFullName($id: String!, $firstName: String!, $lastName: String!) {
  updatePlayerFullName(id: $id, firstName: $firstName, lastName: $lastName)
}
    `;

export function useUpdatePlayerFullNameMutation() {
  return Urql.useMutation<UpdatePlayerFullNameMutation, UpdatePlayerFullNameMutationVariables>(UpdatePlayerFullNameDocument);
};
export const UpdatePlayerFirstNameDocument = gql`
    mutation updatePlayerFirstName($id: String!, $firstName: String!) {
  updatePlayerFirstName(id: $id, firstName: $firstName)
}
    `;

export function useUpdatePlayerFirstNameMutation() {
  return Urql.useMutation<UpdatePlayerFirstNameMutation, UpdatePlayerFirstNameMutationVariables>(UpdatePlayerFirstNameDocument);
};
export const UpdatePlayerLastNameDocument = gql`
    mutation updatePlayerLastName($id: String!, $lastName: String!) {
  updatePlayerLastName(id: $id, lastName: $lastName)
}
    `;

export function useUpdatePlayerLastNameMutation() {
  return Urql.useMutation<UpdatePlayerLastNameMutation, UpdatePlayerLastNameMutationVariables>(UpdatePlayerLastNameDocument);
};
export const UpdatePlayerEntryDocument = gql`
    mutation updatePlayerEntry($id: String!, $entry: Int!) {
  updatePlayerEntry(id: $id, entry: $entry)
}
    `;

export function useUpdatePlayerEntryMutation() {
  return Urql.useMutation<UpdatePlayerEntryMutation, UpdatePlayerEntryMutationVariables>(UpdatePlayerEntryDocument);
};
export const UpdatePlayerWinningsDocument = gql`
    mutation updatePlayerWinnings($id: String!, $winnings: Int!) {
  updatePlayerWinnings(id: $id, winnings: $winnings)
}
    `;

export function useUpdatePlayerWinningsMutation() {
  return Urql.useMutation<UpdatePlayerWinningsMutation, UpdatePlayerWinningsMutationVariables>(UpdatePlayerWinningsDocument);
};
export const UpdatePlayerCashoutDocument = gql`
    mutation updatePlayerCashout($id: String!, $cashout: Int!) {
  updatePlayerCashout(id: $id, cashout: $cashout)
}
    `;

export function useUpdatePlayerCashoutMutation() {
  return Urql.useMutation<UpdatePlayerCashoutMutation, UpdatePlayerCashoutMutationVariables>(UpdatePlayerCashoutDocument);
};
export const CreatePlayerEmailAndPasswordDocument = gql`
    mutation createPlayerEmailAndPassword($id: String!, $email: String!, $password: String!) {
  createPlayerEmailAndPassword(id: $id, email: $email, password: $password)
}
    `;

export function useCreatePlayerEmailAndPasswordMutation() {
  return Urql.useMutation<CreatePlayerEmailAndPasswordMutation, CreatePlayerEmailAndPasswordMutationVariables>(CreatePlayerEmailAndPasswordDocument);
};
export const UpdateRoomDocument = gql`
    mutation updateRoom($input: UpdateRoomInput!) {
  updateRoom(input: $input) {
    id
    name
    location
  }
}
    `;

export function useUpdateRoomMutation() {
  return Urql.useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(UpdateRoomDocument);
};


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Admin: ResolverTypeWrapper<Admin>;
  AdminLog: ResolverTypeWrapper<AdminLog>;
  AdminLogPagination: ResolverTypeWrapper<AdminLogPagination>;
  AdminPagination: ResolverTypeWrapper<AdminPagination>;
  AdminWithRooms: ResolverTypeWrapper<AdminWithRooms>;
  BetDataPoint: ResolverTypeWrapper<BetDataPoint>;
  BetDataPoints: ResolverTypeWrapper<BetDataPoints>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CreateAdminInput: CreateAdminInput;
  CreateGameInput: CreateGameInput;
  CreatePlayerInput: CreatePlayerInput;
  CreateRoomInput: CreateRoomInput;
  DataPoint: ResolverTypeWrapper<DataPoint>;
  DataPoints: ResolverTypeWrapper<DataPoints>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  File: ResolverTypeWrapper<Scalars['File']['output']>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Frequency: Frequency;
  Game: ResolverTypeWrapper<Game>;
  GameSession: ResolverTypeWrapper<GameSession>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntegerCursorPagination: IntegerCursorPagination;
  Mutation: ResolverTypeWrapper<{}>;
  NonNegativeInt: ResolverTypeWrapper<Scalars['NonNegativeInt']['output']>;
  Player: ResolverTypeWrapper<Player>;
  PlayersQuery: ResolverTypeWrapper<PlayersQuery>;
  PositiveInt: ResolverTypeWrapper<Scalars['PositiveInt']['output']>;
  Query: ResolverTypeWrapper<{}>;
  Room: ResolverTypeWrapper<Room>;
  SearchPlayersInput: SearchPlayersInput;
  Station: ResolverTypeWrapper<Station>;
  StationWithSecret: ResolverTypeWrapper<StationWithSecret>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringCursorPagination: StringCursorPagination;
  Transaction: ResolverTypeWrapper<Transaction>;
  TransactionPagination: ResolverTypeWrapper<TransactionPagination>;
  TransactionType: TransactionType;
  UpdateAdminInput: UpdateAdminInput;
  UpdateGameInput: UpdateGameInput;
  UpdatePlayerInput: UpdatePlayerInput;
  UpdateRoomInput: UpdateRoomInput;
  UpdateStationInput: UpdateStationInput;
  getTransactionListFilter: GetTransactionListFilter;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Admin: Admin;
  AdminLog: AdminLog;
  AdminLogPagination: AdminLogPagination;
  AdminPagination: AdminPagination;
  AdminWithRooms: AdminWithRooms;
  BetDataPoint: BetDataPoint;
  BetDataPoints: BetDataPoints;
  Boolean: Scalars['Boolean']['output'];
  CreateAdminInput: CreateAdminInput;
  CreateGameInput: CreateGameInput;
  CreatePlayerInput: CreatePlayerInput;
  CreateRoomInput: CreateRoomInput;
  DataPoint: DataPoint;
  DataPoints: DataPoints;
  DateTime: Scalars['DateTime']['output'];
  File: Scalars['File']['output'];
  Float: Scalars['Float']['output'];
  Game: Game;
  GameSession: GameSession;
  Int: Scalars['Int']['output'];
  IntegerCursorPagination: IntegerCursorPagination;
  Mutation: {};
  NonNegativeInt: Scalars['NonNegativeInt']['output'];
  Player: Player;
  PlayersQuery: PlayersQuery;
  PositiveInt: Scalars['PositiveInt']['output'];
  Query: {};
  Room: Room;
  SearchPlayersInput: SearchPlayersInput;
  Station: Station;
  StationWithSecret: StationWithSecret;
  String: Scalars['String']['output'];
  StringCursorPagination: StringCursorPagination;
  Transaction: Transaction;
  TransactionPagination: TransactionPagination;
  UpdateAdminInput: UpdateAdminInput;
  UpdateGameInput: UpdateGameInput;
  UpdatePlayerInput: UpdatePlayerInput;
  UpdateRoomInput: UpdateRoomInput;
  UpdateStationInput: UpdateStationInput;
  getTransactionListFilter: GetTransactionListFilter;
};

export type AdminResolvers<ContextType = any, ParentType extends ResolversParentTypes['Admin'] = ResolversParentTypes['Admin']> = {
  adminRoleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminLog'] = ResolversParentTypes['AdminLog']> = {
  adminId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminLogPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminLogPagination'] = ResolversParentTypes['AdminLogPagination']> = {
  adminLogs?: Resolver<Array<ResolversTypes['AdminLog']>, ParentType, ContextType>;
  cursor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminPagination'] = ResolversParentTypes['AdminPagination']> = {
  admins?: Resolver<Array<ResolversTypes['Admin']>, ParentType, ContextType>;
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminWithRoomsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminWithRooms'] = ResolversParentTypes['AdminWithRooms']> = {
  adminRoleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roomIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BetDataPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['BetDataPoint'] = ResolversParentTypes['BetDataPoint']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BetDataPointsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BetDataPoints'] = ResolversParentTypes['BetDataPoints']> = {
  dataPoints?: Resolver<Array<ResolversTypes['BetDataPoint']>, ParentType, ContextType>;
  numberOfBets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataPoint'] = ResolversParentTypes['DataPoint']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataPointsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataPoints'] = ResolversParentTypes['DataPoints']> = {
  dataPoints?: Resolver<Array<ResolversTypes['DataPoint']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface FileScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['File'], any> {
  name: 'File';
}

export type GameResolvers<ContextType = any, ParentType extends ResolversParentTypes['Game'] = ResolversParentTypes['Game']> = {
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameCategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imgUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GameSession'] = ResolversParentTypes['GameSession']> = {
  bonus?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  difficulty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entry?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isSessionActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  playerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seedIndex?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startingEntry?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startingWinnings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  winnings?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  attachRoom?: Resolver<ResolversTypes['AdminWithRooms'], ParentType, ContextType, RequireFields<MutationAttachRoomArgs, 'id' | 'roomId'>>;
  cashout?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationCashoutArgs, 'amount' | 'id'>>;
  createAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationCreateAdminArgs, 'input'>>;
  createDiscordChannel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createGame?: Resolver<ResolversTypes['Game'], ParentType, ContextType, RequireFields<MutationCreateGameArgs, 'input'>>;
  createPlayer?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationCreatePlayerArgs, 'input'>>;
  createPlayerEmailAndPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreatePlayerEmailAndPasswordArgs, 'email' | 'id' | 'password'>>;
  createRoom?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<MutationCreateRoomArgs, 'input'>>;
  createStation?: Resolver<ResolversTypes['StationWithSecret'], ParentType, ContextType, RequireFields<MutationCreateStationArgs, 'roomId'>>;
  detachRoom?: Resolver<ResolversTypes['AdminWithRooms'], ParentType, ContextType, RequireFields<MutationDetachRoomArgs, 'id' | 'roomId'>>;
  resetStationSecret?: Resolver<ResolversTypes['StationWithSecret'], ParentType, ContextType, RequireFields<MutationResetStationSecretArgs, 'stationId'>>;
  toggleAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationToggleAdminArgs, 'id'>>;
  toggleStation?: Resolver<ResolversTypes['Station'], ParentType, ContextType, RequireFields<MutationToggleStationArgs, 'id'>>;
  topUpBalance?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationTopUpBalanceArgs, 'amount' | 'id'>>;
  updateAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationUpdateAdminArgs, 'input'>>;
  updateGame?: Resolver<ResolversTypes['Game'], ParentType, ContextType, RequireFields<MutationUpdateGameArgs, 'input'>>;
  updatePassword?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'id' | 'password'>>;
  updatePlayer?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<MutationUpdatePlayerArgs, 'input'>>;
  updatePlayerCardSerialNumber?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerCardSerialNumberArgs, 'playerId'>>;
  updatePlayerCashout?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerCashoutArgs, 'cashout' | 'id'>>;
  updatePlayerEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerEmailArgs, 'email' | 'id'>>;
  updatePlayerEntry?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerEntryArgs, 'entry' | 'id'>>;
  updatePlayerFirstName?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerFirstNameArgs, 'firstName' | 'id'>>;
  updatePlayerFullName?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerFullNameArgs, 'firstName' | 'id' | 'lastName'>>;
  updatePlayerLastName?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerLastNameArgs, 'id' | 'lastName'>>;
  updatePlayerPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerPasswordArgs, 'id' | 'newPassword' | 'oldPassword'>>;
  updatePlayerPhoneNumber?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerPhoneNumberArgs, 'id' | 'phoneNumber'>>;
  updatePlayerWinnings?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePlayerWinningsArgs, 'id' | 'winnings'>>;
  updateRoom?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<MutationUpdateRoomArgs, 'input'>>;
  updateRoomBps?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateRoomBpsArgs, 'bps' | 'roomId'>>;
  updateStation?: Resolver<ResolversTypes['Station'], ParentType, ContextType, RequireFields<MutationUpdateStationArgs, 'input'>>;
};

export interface NonNegativeIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NonNegativeInt'], any> {
  name: 'NonNegativeInt';
}

export type PlayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Player'] = ResolversParentTypes['Player']> = {
  cardSerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cashout?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entry?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  isPlayFromHome?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSessionActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  room?: Resolver<Maybe<ResolversTypes['Room']>, ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  winnings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlayersQuery'] = ResolversParentTypes['PlayersQuery']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  players?: Resolver<Array<ResolversTypes['Player']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PositiveIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PositiveInt'], any> {
  name: 'PositiveInt';
}

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  countStationsByRoomId?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCountStationsByRoomIdArgs, 'roomId'>>;
  countTransactionsByRoomId?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCountTransactionsByRoomIdArgs, 'roomId'>>;
  getAdmin?: Resolver<ResolversTypes['Admin'], ParentType, ContextType>;
  getAdminById?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<QueryGetAdminByIdArgs, 'adminId'>>;
  getAdminList?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, Partial<QueryGetAdminListArgs>>;
  getAdminLogListByAdminId?: Resolver<ResolversTypes['AdminLogPagination'], ParentType, ContextType, RequireFields<QueryGetAdminLogListByAdminIdArgs, 'adminId'>>;
  getAllTransactions?: Resolver<ResolversTypes['TransactionPagination'], ParentType, ContextType>;
  getAverageBetByGameName?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetAverageBetByGameNameArgs, 'frequency' | 'gameName'>>;
  getAverageBetByPlayerId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetAverageBetByPlayerIdArgs, 'frequency' | 'playerId'>>;
  getAverageSessionDurationByPlayerId?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryGetAverageSessionDurationByPlayerIdArgs, 'playerId'>>;
  getBetsByGameName?: Resolver<ResolversTypes['BetDataPoints'], ParentType, ContextType, RequireFields<QueryGetBetsByGameNameArgs, 'frequency' | 'gameName'>>;
  getBetsByRoomId?: Resolver<ResolversTypes['BetDataPoints'], ParentType, ContextType, RequireFields<QueryGetBetsByRoomIdArgs, 'frequency' | 'roomId'>>;
  getBetsByStationId?: Resolver<ResolversTypes['BetDataPoints'], ParentType, ContextType, RequireFields<QueryGetBetsByStationIdArgs, 'frequency' | 'stationId'>>;
  getGGRByAdminId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetGgrByAdminIdArgs, 'adminId' | 'frequency' | 'startDate'>>;
  getGGRByPlayerId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetGgrByPlayerIdArgs, 'frequency' | 'playerId' | 'startDate'>>;
  getGGRByRoomId?: Resolver<ResolversTypes['DataPoints'], ParentType, ContextType, RequireFields<QueryGetGgrByRoomIdArgs, 'frequency' | 'roomId' | 'startDate'>>;
  getGameList?: Resolver<Array<ResolversTypes['Game']>, ParentType, ContextType>;
  getOperatorById?: Resolver<ResolversTypes['Admin'], ParentType, ContextType, RequireFields<QueryGetOperatorByIdArgs, 'adminId'>>;
  getOperatorList?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, Partial<QueryGetOperatorListArgs>>;
  getPersonaList?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  getPersonaListBelow?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  getPlayerById?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<QueryGetPlayerByIdArgs, 'playerId'>>;
  getPlayerByIdOrCardSerialNumber?: Resolver<ResolversTypes['Player'], ParentType, ContextType, RequireFields<QueryGetPlayerByIdOrCardSerialNumberArgs, 'playerIdOrCardSerialNumber'>>;
  getPlayerList?: Resolver<ResolversTypes['PlayersQuery'], ParentType, ContextType, Partial<QueryGetPlayerListArgs>>;
  getPlayerListByRoomId?: Resolver<ResolversTypes['PlayersQuery'], ParentType, ContextType, RequireFields<QueryGetPlayerListByRoomIdArgs, 'roomId'>>;
  getRoomById?: Resolver<ResolversTypes['Room'], ParentType, ContextType, RequireFields<QueryGetRoomByIdArgs, 'roomId'>>;
  getRoomGGRBps?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryGetRoomGgrBpsArgs, 'roomId'>>;
  getRoomList?: Resolver<Array<ResolversTypes['Room']>, ParentType, ContextType>;
  getRoomListByAdminId?: Resolver<Array<ResolversTypes['Room']>, ParentType, ContextType, RequireFields<QueryGetRoomListByAdminIdArgs, 'adminId'>>;
  getRoomPayToProvider?: Resolver<Maybe<ResolversTypes['NonNegativeInt']>, ParentType, ContextType, RequireFields<QueryGetRoomPayToProviderArgs, 'roomId' | 'startDate'>>;
  getSalesRepList?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, Partial<QueryGetSalesRepListArgs>>;
  getStationActiveVersionInstaller?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getStationAppActiveVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getStationById?: Resolver<ResolversTypes['Station'], ParentType, ContextType, RequireFields<QueryGetStationByIdArgs, 'stationId'>>;
  getStationList?: Resolver<Array<Maybe<ResolversTypes['Station']>>, ParentType, ContextType>;
  getStationListByRoomId?: Resolver<Array<Maybe<ResolversTypes['Station']>>, ParentType, ContextType, RequireFields<QueryGetStationListByRoomIdArgs, 'roomId'>>;
  getStationsForOperator?: Resolver<Array<ResolversTypes['Station']>, ParentType, ContextType, RequireFields<QueryGetStationsForOperatorArgs, 'adminId'>>;
  getSuperAdminList?: Resolver<ResolversTypes['AdminPagination'], ParentType, ContextType, Partial<QueryGetSuperAdminListArgs>>;
  getTransactionList?: Resolver<ResolversTypes['TransactionPagination'], ParentType, ContextType, Partial<QueryGetTransactionListArgs>>;
  getTransactionListByPlayerId?: Resolver<ResolversTypes['TransactionPagination'], ParentType, ContextType, RequireFields<QueryGetTransactionListByPlayerIdArgs, 'playerId'>>;
  getTransactionListByRoomId?: Resolver<ResolversTypes['TransactionPagination'], ParentType, ContextType, RequireFields<QueryGetTransactionListByRoomIdArgs, 'roomId'>>;
  issueCardValidation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIssueCardValidationArgs, 'playerId'>>;
  searchPlayers?: Resolver<ResolversTypes['PlayersQuery'], ParentType, ContextType, RequireFields<QuerySearchPlayersArgs, 'input'>>;
};

export type RoomResolvers<ContextType = any, ParentType extends ResolversParentTypes['Room'] = ResolversParentTypes['Room']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isReadyForUpdate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Station'] = ResolversParentTypes['Station']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameSessionList?: Resolver<Maybe<Array<Maybe<ResolversTypes['GameSession']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stationAppVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationWithSecretResolvers<ContextType = any, ParentType extends ResolversParentTypes['StationWithSecret'] = ResolversParentTypes['StationWithSecret']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = {
  adminId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  playerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionPagination'] = ResolversParentTypes['TransactionPagination']> = {
  cursor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Admin?: AdminResolvers<ContextType>;
  AdminLog?: AdminLogResolvers<ContextType>;
  AdminLogPagination?: AdminLogPaginationResolvers<ContextType>;
  AdminPagination?: AdminPaginationResolvers<ContextType>;
  AdminWithRooms?: AdminWithRoomsResolvers<ContextType>;
  BetDataPoint?: BetDataPointResolvers<ContextType>;
  BetDataPoints?: BetDataPointsResolvers<ContextType>;
  DataPoint?: DataPointResolvers<ContextType>;
  DataPoints?: DataPointsResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  File?: GraphQLScalarType;
  Game?: GameResolvers<ContextType>;
  GameSession?: GameSessionResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NonNegativeInt?: GraphQLScalarType;
  Player?: PlayerResolvers<ContextType>;
  PlayersQuery?: PlayersQueryResolvers<ContextType>;
  PositiveInt?: GraphQLScalarType;
  Query?: QueryResolvers<ContextType>;
  Room?: RoomResolvers<ContextType>;
  Station?: StationResolvers<ContextType>;
  StationWithSecret?: StationWithSecretResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  TransactionPagination?: TransactionPaginationResolvers<ContextType>;
};

