import { useState, useEffect } from "react";
import { orderBy } from "lodash";
import toast from "react-hot-toast";

// @mui
import {
  Container,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Alert,
  TableSortLabel,
  CircularProgress,
  Typography,
  TableCell,
  TableRow,
  Tooltip,
  Checkbox,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  IconButton,
} from "@mui/material";
import Page from "../components/Page";
import useSettings from "../hooks/useSettings";
import {
  useGetStationListByRoomIdQuery,
  useGetStationAppActiveVersionQuery,
  useGetRoomListQuery,
  useUpdateRoomMutation,
} from "../generated/graphql";
import useAuth from "../hooks/useAuth";
import Iconify from "../components/Iconify";
import SvgIconStyle from "../components/other/SvgIconStyle";
import { FormControlLabel } from "@mui/material";

// ----------------------------------------------------------------------

export default function PageStations() {
  const { themeStretch } = useSettings();
  const [sortDirectionAsc, setSortDirectionAsc] = useState<boolean>(false);
  const [sortByAttribute, setSortByAttribute] =
    useState<string>("gameSessionList");

  const { activeRoomId } = useAuth();
  const [{ data: getStationsResults, error: getStationsError }, getStations] =
    useGetStationListByRoomIdQuery({
      variables: {
        roomId: activeRoomId as string,
      },
      pause: !activeRoomId,
    });
  const stations = getStationsResults?.getStationListByRoomId;

  const [{ data: getStationsAppActiveVersionResults }] =
    useGetStationAppActiveVersionQuery({});
  const stationActiveVersion =
    getStationsAppActiveVersionResults?.getStationAppActiveVersion;

  useEffect(() => {
    if (getStationsError) toast.error(`ERR: ${getStationsError}`);
  }, [getStationsError]);

  const sortStations = (users: any) => {
    const direction = sortDirectionAsc ? "asc" : "desc";
    const sorted = orderBy(users, (o) => o[sortByAttribute], direction);
    return sorted;
  };

  const changeSort = (attribute: any) => {
    if (attribute === sortByAttribute) {
      const newSort = !sortDirectionAsc;
      setSortDirectionAsc(newSort);
    } else setSortByAttribute(attribute);
  };

  const [roomReadyForUpdateModal, setRoomReadyForUpdateModal] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [{ data: roomListData, error: roomListError }, getRoom] =
    useGetRoomListQuery({});
  const roomList = roomListData?.getRoomList;
  const roomInformation = roomList?.find((room) => room.id === activeRoomId);

  const [{}, updateRoom] = useUpdateRoomMutation();
  const handleUpdateRoomUpdater = async () => {
    const updatedRoomResponse = await updateRoom({
      input: {
        roomId: roomInformation?.id!,
        isReadyForUpdate: isActive,
      },
    });
    if (updatedRoomResponse.data?.updateRoom) {
      toast.success("Room details updated successfully");
      setRoomReadyForUpdateModal(false);
    } else {
      toast.error("Error updating room details");
    }
  };

  return (
    <Page title="Stations">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "space-between" },
            }}
          >
            Stations
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            boxShadow:
              "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
            borderRadius: "16px",
            padding: 2,
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                height: { xs: "auto", sm: "50px" },
                width: { xs: "100%", sm: "auto" },
              }}
            >
              {getStationsError?.message ? (
                <Alert severity="error">
                  {getStationsError?.message.toString()}!
                </Alert>
              ) : stations && stations.length === 0 ? (
                <Alert severity="warning">No Stations Found!</Alert>
              ) : (
                <Alert severity="info">{stations?.length} Stations Found</Alert>
              )}

              <IconButton
                edge="end"
                sx={{
                  ml: 1,
                  p: 2,
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={() => {
                  getStations({
                    requestPolicy: "network-only",
                  });
                  toast.success("Stations Refreshed!");
                }}
              >
                <Iconify icon={"solar:refresh-bold"} width={25} height={25} />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: "flex",
                ml: "auto",
              }}
            >
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={() => setRoomReadyForUpdateModal(true)}
              >
                Room Updater Status
              </Button>

              <Alert sx={{ my: "auto" }} severity="info">
                Latest Active Version: {stationActiveVersion}{" "}
              </Alert>
            </Box>
          </Box>

          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" sx={{ overflowX: "scroll" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <TableSortLabel
                      onClick={() => changeSort("id")}
                      active={sortByAttribute === "id"}
                      direction={sortDirectionAsc ? "asc" : "desc"}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      onClick={() => changeSort("gameSessionList")}
                      active={sortByAttribute === "gameSessionList"}
                      direction={sortDirectionAsc ? "asc" : "desc"}
                    >
                      Player Session
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      onClick={() => changeSort("stationAppVersion")}
                      active={sortByAttribute === "stationAppVersion"}
                      direction={sortDirectionAsc ? "asc" : "desc"}
                    >
                      Version
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      onClick={() => changeSort("createdAt")}
                      active={sortByAttribute === "createdAt"}
                      direction={sortDirectionAsc ? "asc" : "desc"}
                    >
                      Created At
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      onClick={() => changeSort("isActive")}
                      active={sortByAttribute === "isActive"}
                      direction={sortDirectionAsc ? "asc" : "desc"}
                    >
                      Active
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortStations(stations).map((station, index) => (
                  <Row
                    key={station.id}
                    station={station}
                    index={index}
                    stationActiveVersion={stationActiveVersion}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!stations && (
            <Box
              sx={{
                width: "100%",
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ width: "100%" }} />
            </Box>
          )}
        </Box>
      </Container>

      <Dialog
        onClose={() => setRoomReadyForUpdateModal(false)}
        open={roomReadyForUpdateModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          Room Updater
          <IconButton
            aria-label="close"
            onClick={() => setRoomReadyForUpdateModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "500px",
            bgcolor: "#2e3c5a",
          }}
        >
          <Typography variant="body1" component={"span"}>
            CAUTION! Activating this will affect all stations in this room. It
            will allow all machines to update to latest active version of the
            app.
            {`(${stationActiveVersion})`}
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="body1" component={"span"}>
              Status
            </Typography>
            <Box sx={{ mx: "auto", display: "flex", alignItems: "center" }}>
              <Typography variant="body2" component={"span"}>
                Inactive
              </Typography>

              <Switch
                size="medium"
                sx={{}}
                defaultChecked={roomInformation?.isReadyForUpdate!}
                onChange={() => {
                  if (isActive === false) {
                    setIsActive(true);
                  } else {
                    setIsActive(false);
                  }
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="body2" component={"span"}>
                Active
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            variant="contained"
            autoFocus
            onClick={() => {
              handleUpdateRoomUpdater();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

function Row(props: {
  station: any;
  index: number;
  stationActiveVersion: string | undefined;
}) {
  const { station, index, stationActiveVersion } = props;
  const rowColor = index % 2 ? "#3d4155" : "#2e3140";

  return (
    <>
      <TableRow
        sx={{
          height: "30px",
          background: rowColor,
        }}
      >
        <TableCell align="left">
          <Tooltip title={station.id}>
            <Box>
              {station.id.slice(0, 8)}
              {station.id.length > 8 ? "..." : ""}
            </Box>
          </Tooltip>
        </TableCell>

        <TableCell align="left">
          {station.gameSessionList[0] ? (
            <Box sx={{ display: "flex" }}>
              <a
                style={{ textDecoration: "none", display: "flex" }}
                href={`/player/?id=${station.gameSessionList[0].playerId}`}
              >
                <svg
                  style={{ display: "inline", verticalAlign: "middles" }}
                  width="25"
                  height="25"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M9.25968 4.35211C9.64783 4.35211 10.0222 4.40304 10.3828 4.50489C10.7435 4.604 11.0793 4.74577 11.3904 4.93021C11.7042 5.11464 11.9891 5.33625 12.2451 5.59501C12.5039 5.85103 12.7255 6.13594 12.9099 6.44977C13.0944 6.76084 13.2361 7.09668 13.3352 7.4573C13.4371 7.81792 13.488 8.19231 13.488 8.58045C13.488 8.9686 13.4371 9.34299 13.3352 9.70361C13.2361 10.0642 13.0944 10.4014 12.9099 10.7153C12.7255 11.0263 12.5039 11.3113 12.2451 11.57C11.9891 11.826 11.7042 12.0463 11.3904 12.2307C11.0793 12.4151 10.7435 12.5583 10.3828 12.6601C10.0222 12.7592 9.64783 12.8088 9.25968 12.8088C8.87153 12.8088 8.49715 12.7592 8.13653 12.6601C7.77591 12.5583 7.43869 12.4151 7.12487 12.2307C6.8138 12.0463 6.52888 11.826 6.27011 11.57C6.0141 11.3113 5.79387 11.0263 5.60944 10.7153C5.425 10.4014 5.28185 10.0642 5.17999 9.70361C5.08089 9.34299 5.03134 8.9686 5.03134 8.58045C5.03134 8.19231 5.08089 7.81792 5.17999 7.4573C5.28185 7.09668 5.425 6.76084 5.60944 6.44977C5.79387 6.13594 6.0141 5.85103 6.27011 5.59501C6.52888 5.33625 6.8138 5.11464 7.12487 4.93021C7.43869 4.74577 7.77591 4.604 8.13653 4.50489C8.49715 4.40304 8.87153 4.35211 9.25968 4.35211Z"
                    fill="#27AE60"
                  />
                </svg>

                <p>Active </p>
              </a>
            </Box>
          ) : (
            <Box sx={{ display: "flex" }}>
              <svg
                style={{ display: "inline", verticalAlign: "middles" }}
                width="25"
                height="25"
                viewBox="0 0 18 18"
              >
                <path
                  d="M9.25968 4.35211C9.64783 4.35211 10.0222 4.40304 10.3828 4.50489C10.7435 4.604 11.0793 4.74577 11.3904 4.93021C11.7042 5.11464 11.9891 5.33625 12.2451 5.59501C12.5039 5.85103 12.7255 6.13594 12.9099 6.44977C13.0944 6.76084 13.2361 7.09668 13.3352 7.4573C13.4371 7.81792 13.488 8.19231 13.488 8.58045C13.488 8.9686 13.4371 9.34299 13.3352 9.70361C13.2361 10.0642 13.0944 10.4014 12.9099 10.7153C12.7255 11.0263 12.5039 11.3113 12.2451 11.57C11.9891 11.826 11.7042 12.0463 11.3904 12.2307C11.0793 12.4151 10.7435 12.5583 10.3828 12.6601C10.0222 12.7592 9.64783 12.8088 9.25968 12.8088C8.87153 12.8088 8.49715 12.7592 8.13653 12.6601C7.77591 12.5583 7.43869 12.4151 7.12487 12.2307C6.8138 12.0463 6.52888 11.826 6.27011 11.57C6.0141 11.3113 5.79387 11.0263 5.60944 10.7153C5.425 10.4014 5.28185 10.0642 5.17999 9.70361C5.08089 9.34299 5.03134 8.9686 5.03134 8.58045C5.03134 8.19231 5.08089 7.81792 5.17999 7.4573C5.28185 7.09668 5.425 6.76084 5.60944 6.44977C5.79387 6.13594 6.0141 5.85103 6.27011 5.59501C6.52888 5.33625 6.8138 5.11464 7.12487 4.93021C7.43869 4.74577 7.77591 4.604 8.13653 4.50489C8.49715 4.40304 8.87153 4.35211 9.25968 4.35211Z"
                  fill="red"
                />
              </svg>

              <p>No Session</p>
            </Box>
          )}
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "bold" }}>
          {stationActiveVersion !== station.stationAppVersion && (
            <Box sx={{ display: "flex" }}>
              {station.stationAppVersion && (
                <>
                  <Iconify
                    icon="bi:exclamation-triangle-fill"
                    sx={{
                      width: 25,
                      height: 25,
                      color: "yellow",
                    }}
                  />
                  <Tooltip title="Station App Version is not the latest version">
                    <p
                      style={{
                        marginLeft: "10px",
                        marginTop: "auto",
                        color: "red",
                      }}
                    >
                      {station.stationAppVersion}
                    </p>
                  </Tooltip>
                </>
              )}

              {!station.stationAppVersion && (
                <p
                  style={{
                    marginLeft: "10px",
                    marginTop: "auto",
                    // color: "red",
                  }}
                >
                  N/A
                </p>
              )}
            </Box>
          )}
          {stationActiveVersion == station.stationAppVersion && (
            <Box sx={{ display: "flex" }}>
              <p
                style={{
                  marginLeft: "10px",
                  marginTop: "auto",
                  color: "white",
                }}
              >
                {station.stationAppVersion}
              </p>
            </Box>
          )}

          {/* {station.stationAppVersion ? station.stationAppVersion : "N/A"} */}
        </TableCell>
        <TableCell align="left">
          {toHumanReadableDate(station.createdAt)}
        </TableCell>
        <TableCell align="left">
          {station.isActive ? (
            <Typography
              variant="body2"
              component={"span"}
              sx={{ ml: 1, flex: 1 }}
            >
              <SvgIconStyle
                src={`/assets/icons/yes.svg`}
                sx={{
                  width: 25,
                  height: 25,
                  color: "green",
                }}
              />
            </Typography>
          ) : (
            <Typography
              variant="body2"
              component={"span"}
              sx={{ ml: 1, flex: 1 }}
            >
              <SvgIconStyle
                src={`/assets/icons/no.svg`}
                sx={{ width: 25, height: 25, color: "red" }}
              />
            </Typography>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

const toHumanReadableDate = (isoString: string) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return `${formattedDate} at ${formattedTime}`;
};
